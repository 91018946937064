// -------------------------------------------------------------------------------------------------
// West London Programs AJAX enhancements
// -------------------------------------------------------------------------------------------------

var link_patterns  	= {};
link_patterns.buy  	= new RegExp(/.+\/basket\?add=(\d+)$/);
link_patterns.info 	= new RegExp(/javascript:popupinfo\((\d+)\)/);
link_patterns.close = new RegExp(/close_float_over/);

var basketcount; // Set by inline javascript

// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// AJAX POWERED SHOPPING BASKET UPDATES AND INFO

// Attach event handler to intercept click events
if (window.addEventListener)
{
	window.addEventListener('click', handle_click_events, false);  // W3C
}
else if (window.attachEvent)
{
	document.attachEvent('onclick', handle_click_events); // IE  - Quirks Mode
}
else
{
	document.onclick = handle_click_events; // IE5 Mac, and older browsers
}

// -------------------------------------------------------------------------------------------------

function handle_click_events(e)
{
	var href = null;
	var func;
	
	var targ = dutil.get_target(e);
	if (targ.tagName == 'A')
	{
		href = targ.href; // A link has been clicked
	}
	else if (targ.parentNode.tagName == 'A')
	{
		href = targ.parentNode.href; // A image inside a link has been clicked
	}
	if (href === null) return;
	
	//if (link_patterns.buy.test(href))  	func = ajax_add_to_basket;
	if (link_patterns.info.test(href)) 	func = ajax_get_info;
	if (link_patterns.close.test(href)) func = close_float_over;

	// If 'func' is defined, execute it and prevent the default click behaviour
	if (func)
	{
		targ.blur();
		func(href, e);
		if (e.preventDefault) e.preventDefault();
		return false;
	}
}

// -------------------------------------------------------------------------------------------------

function ajax_add_to_basket(href, e)
{
	var m = href.match(link_patterns.buy);
	var prog = m[1];
	var url = 'ajax/request_handler?xaction=basket_add&prog=' +  prog;
	var acd = dutil.get_click_position(e);
	acd.upd	= true; // update the basket items count in the floating basket at the top of the page
	simajax.loadXMLDoc(url, populate_floating_div, acd);
	
	// Dim 'BUY' link and remove click
	var targ = dutil.get_target(e);
	targ.parentNode.href = '#';
	dutil.set_opacity(targ.parentNode, 0.33);
}

// -------------------------------------------------------------------------------------------------

function ajax_get_info(href, e)
{
	var m = href.match(link_patterns.info);
	var prog = m[1];
	var url = 'ajax/request_handler?xaction=info_get&prog=' +  prog;
	var acd = dutil.get_click_position(e);
	acd.upd	= false;
	simajax.loadXMLDoc(url, populate_floating_div, acd);
}

// -------------------------------------------------------------------------------------------------

function close_float_over()
{
	var box = dutil.get_DOM_ref('ajax_message_box');
	dutil.fade_out(box);
}

// -------------------------------------------------------------------------------------------------

function populate_floating_div(response, acd)
{
	var box = dutil.get_DOM_ref('ajax_message_box');
	box.innerHTML  		= response;
	box.style.display  	= 'block'; // Make sure element 'has layout' before attempting to calculate dimensions
	var size 			= dutil.get_dimensions(box);
	box.style.left 		= (acd.x - (size.x/2) - 5) + 'px';
	box.style.top  		= (acd.y - size.y - 5) + 'px';
	dutil.fade_in(box);
	
	if (acd.upd)
	{
		// Update the basket count in the floating basket
		basketcount++;
		dutil.get_DOM_ref('floatbasket').innerHTML = '<a title="You have ' + basketcount + ' item' + (basketcount > 1 ? 's' : '') + ' in your basket" href="/basket"><strong>' + basketcount + '</strong><span></span></a>';
	}
}

// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// DOM UTILITIES By Rich K

var dutil = {};

// -------------------------------------------------------------------------------------------------

dutil.fade_in = function(obj)
{
	if (typeof obj == 'string') obj = dutil.get_DOM_ref(obj);
	if (obj.style.display == 'none') obj.style.display = 'block';
	obj.my_opac = 0;
	dutil.fade_in_do(obj);
};

dutil.fade_in_do = function(obj)
{
	if (typeof obj == 'string') obj = dutil.get_DOM_ref(obj);
	if (obj.my_opac > 1) obj.my_opac = 1;
	dutil.set_opacity(obj, obj.my_opac);
	if (obj.my_opac < 1)
	{
		var t = setTimeout('dutil.fade_in_do("'+obj.id+'")', 50);
	}
	obj.my_opac += 0.15;
};

// -------------------------------------------------------------------------------------------------

dutil.fade_out = function(obj)
{
	if (typeof obj == 'string') obj = dutil.get_DOM_ref(obj);
	obj.my_opac = 1;
	dutil.fade_out_do(obj);
};

dutil.fade_out_do = function(obj)
{
	if (typeof obj == 'string') obj = dutil.get_DOM_ref(obj);
	if (obj.my_opac < 0) obj.my_opac = 0;
	dutil.set_opacity(obj, obj.my_opac);
	if (obj.my_opac > 0)
	{
		var t = setTimeout('dutil.fade_out_do("'+obj.id+'")', 50);
	}
	else
	{
		obj.style.display = 'none';
	}
	obj.my_opac -= 0.15;
};

// -------------------------------------------------------------------------------------------------

dutil.set_opacity = function(obj, fraction)
{
	if (typeof obj == 'string') obj = dutil.get_DOM_ref(obj);
	obj.style.filter = 'alpha(opacity=' + (fraction * 100) + ')';
	obj.style.opacity = fraction;
};

// -------------------------------------------------------------------------------------------------

dutil.get_DOM_ref = function(id)
{
	// cross-browser function to get an object's DOM reference, given its id
	if (document.getElementById && document.getElementById(id)) return document.getElementById(id); // W3C DOM
	if (document.all && document.all(id)) return document.all(id); // MSIE 4/5/6
	return false;
};

// -------------------------------------------------------------------------------------------------

dutil.get_dimensions = function(obj)
{
	// Note in IE, an element must 'have layout' for this to return a non-zero value
	var size = {};
	if (typeof obj == 'string') obj = dutil.get_DOM_ref(obj);
	size.x = obj.offsetWidth  ? obj.offsetWidth  : obj.clientWidth;
	size.y = obj.offsetHeight ? obj.offsetHeight : obj.clientHeight;
	return size;
};

// -------------------------------------------------------------------------------------------------

dutil.get_target = function(e)
{
	// Get the target of an event
	var targ;
	if (!e) e = window.event;
	if (e.target) targ = e.target;
	else if (e.srcElement) targ = e.srcElement;
	if (targ.nodeType == 3) targ = targ.parentNode; // defeat Safari bug
	return targ;
};

// -------------------------------------------------------------------------------------------------

dutil.get_click_position = function(e)
{
	var pos = {};
	if (!e) e = window.event;
	if (e.pageX || e.pageY)
	{
		pos.x = e.pageX;
		pos.y = e.pageY;
	}
	else if (e.clientX || e.clientY)
	{
		pos.x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
		pos.y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
	}
	return pos;
};

// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// SIMPLE AJAX By Rich K, adapted from code by Rich C

var simajax = {};

simajax.loadXMLDoc = function(url, callback, additional_callback_data, async)
{
	if (typeof async == 'undefined') async = true;

	simajax.req = false;
	
    if (window.XMLHttpRequest && !(window.ActiveXObject))
    {
	    // branch for native XMLHttpRequest object
    	try
    	{
			simajax.req = new XMLHttpRequest();
        }
        catch(e)
        {
			simajax.req = false;
        }
    }
    else if (window.ActiveXObject)
    {
		// branch for IE/Windows ActiveX version
       	try
       	{
        	simajax.req = new ActiveXObject('Msxml2.XMLHTTP');
      	}
      	catch(e)
      	{
        	try
        	{
          		simajax.req = new ActiveXObject('Microsoft.XMLHTTP');
        	}
        	catch(e)
        	{
          		simajax.req = false;
        	}
		}
    }
	
	if (simajax.req)
	{
		simajax.req.onreadystatechange = 	function()
											{
												resp = simajax.processReqChange();
												if (resp) callback(resp, additional_callback_data);
											};
		simajax.req.open('GET', url, async);
		simajax.req.send('');
	}
};

// -------------------------------------------------------------------------------------------------

simajax.processReqChange = function()
{
    if (simajax.req.readyState == 4)
    {
	    // only if req shows "loaded"
        if (simajax.req.status == 200)
        {
        	// only if "OK"
            return simajax.req.responseText;
        }
        else
        {
            console.log("There was a problem retrieving the XML data:\n" + simajax.req.statusText);
            return false;
        }
		console.dir(simajax.req);
    }
};


function popupinfo(idvar){
	winpops=window.open("/programinfo.php?programid=" + idvar + "","","width=300,height=300,scrollbars,resizable,");
}

$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();
});